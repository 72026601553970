<template>
  <div class="mcr-poem">
    <div class="number">{{ number }}</div>
    <div class="symbol">{{ symbol }}</div>
    <div class="pinyin">{{ pinyin }}</div>
    <div class="meaning">{{ meaning }}</div>
  </div>
</template>

<script>
export default {
  props: {
    number: String,
    symbol: String,
    pinyin: String,
    meaning: String,
  },
};
</script>

<style lang="scss" scoped>
.mcr-poem {
  display: flex;
  flex-direction: column;
  text-align: center;

  .symbol,
  .pinyin,
  .meaning {
    padding: 0 10px;
  }

  .number {
    padding: 10px;
    margin-bottom: 10px;
    background: var(--highlight-background);
    font-weight: bold;
  }

  .symbol,
  .pinyin {
    font-size: 1.5em;
  }
}
</style>
